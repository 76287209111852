<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event, multiple, multipleReturnType)"
			label="text"
			track-by="value"
			:multiple="multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:close-on-select="false"
			:searchable="true"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
			</template>
			<span slot="noOptions">{{ $t("no_options") }}</span>
			<span slot="noResult">{{ $t("no_result") }}</span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"
		/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import ParameterService from "@/services/ParameterService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			code: {
				type: String
			},
			value: {
				default: null,
			},
			sort: {
				type: String,
				default: 'name'
			},
			multiple: {
				type: Boolean,
				default: true,
			},
			multipleReturnType: {
				type: String,
				default: 'array',
			},
			validateError: {
				type: String,
				default: "",
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType);
			}
		},
		data() {
			return {
				selected: null,
				options: [],
			};
		},
		created() {
			this.getOptions();
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				const config = {
					params: {
						sort: this.sort,
						limit: -1
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				
				ParameterService.getItems(this.code, config)
				                .then(response => {
					                const data = response.data.data;
					                if (data && data.items) {
						                data.items.map(item => {
							                this.options.push({
								                value: item.code,
								                text: this.$i18n.locale == 'tr' ? item.name : item.name_en,
							                });
						                });
					                }
				                })
				                .then(() => {
					                this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
				                })
			}
		}
	}
</script>
